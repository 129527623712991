<template>
    <a-menu class="menu" mode="inline" theme="dark" :defaultOpenKeys="defaultOpenKeys" v-model="select" :inline-collapsed="collapsed" @click="handleClick">
        <component :is="m.children ? 'a-sub-menu' : 'a-menu-item'" v-for="m in menu" :key="m.path">
            <template v-if="m.children">
                <span slot="title"><a-icon class="menu-icon" :type="m.icon" /><span>{{m.title}}</span></span>
                <a-menu-item v-for="c in m.children" :key="c.path">{{c.title}}</a-menu-item>
            </template>
            <template v-else>
                <a-icon class="menu-icon" :type="m.icon" />
                <span>{{m.title}}</span>
            </template>
        </component>
    </a-menu>
</template>

<script>
    export default {
        name: "menu-bar",
        data() {
            return {
                defaultOpenKeys: [],
                select: [this.$route.path],
                menu: [
                    {
                        title: '专辑管理',
                        path: '/entity/album',
                        icon: 'appstore'
                    },
                    {
                        title: '音频管理',
                        path: '/entity/audio',
                        icon: 'audio'
                    },
                    {
                        title: '活动管理',
                        path: '/entity/activity',
                        icon: 'star'
                    },
                    {
                        title: '老师管理',
                        path: '/entity/teacher',
                        icon: 'team'
                    },
                    {
                        title: '展览管理',
                        path: '/entity/exhibition',
                        icon: 'shop'
                    },
                    {
                        title: '博物馆管理',
                        path: '/entity/museum',
                        icon: 'home'
                    },
                    {
                        title: 'Banner配置',
                        path: '/entity/banner',
                        icon: 'picture'
                    },
                    {
                        title: '身份管理',
                        path: '/identity',
                        icon: 'team'
                    },
                ]
            }
        },
        computed: {
            collapsed () {
                return this.$store.getters.collapsed;
            }
        },
        watch: {
            $route(val) {
                this.select = [val.path];
            }
        },
        created() {
            this.setOpenMenu(this.$route.path);
        },
        methods: {
            setOpenMenu(path) {
                let menu = this.menu;
                for(let i = 0, l = menu.length; i < l; i ++) {
                    let m = menu[i];
                    if(m.path == path) {
                        break;
                    } else if(m.children) {
                        let child = m.children.find(c => c.path == path);
                        if(child) {
                            this.defaultOpenKeys = [m.path];
                            break;
                        }
                    }
                }
            },
            handleClick(e) {
                let path = e.key;
                if(path != this.$route.path) {
                    this.$router.push(path);
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .menu {
        flex-shrink: 0;
        padding: 12px 0;
        width: 100%;
        box-shadow: @shadow-1-right;
        overflow-x: hidden;
        overflow-y: auto;
        //滚动条
        &::-webkit-scrollbar {
            width: 4px;
            height: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: @primary-6;
            border-radius: 5px;
        }
    }
    .menu-icon {
        font-size: 16px;
    }
</style>

<template>
    <div class="main" :class="{collapsed}">
        <div class="left flex-box vertical">
            <router-link :to="{name: 'List', params: {type: 'album'}}" class="logo-img-box">
                <img class="logo-img" src="../assets/logo.jpg">
                <div class="logo-title">啄物鸟后台管理系统</div>
            </router-link>
            <menu-bar class="flex-grow"></menu-bar>
        </div>
        <div class="right flex-box vertical">
            <nav-bar></nav-bar>
            <title-bar></title-bar>
            <div class="area">
                <keep-alive>
                    <router-view v-if="$route.meta.keepAlive"></router-view>
                </keep-alive>
                <router-view v-if="!$route.meta.keepAlive"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import menuBar from '../components/menu-bar'
    import navBar from '../components/nav-bar';
    import titleBar from '../components/title-bar'
    export default {
        name: 'Home',
        components: {
            navBar,
            menuBar,
            titleBar,
        },
        computed: {
            collapsed () {
                return this.$store.getters.collapsed;
            }
        },
        data() {
            return {

                visible: true
            }
        }
    }
</script>

<style lang="less">
    .left {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 256px;
        transition: all 0.2s;
    }
    .right {
        position: absolute;
        top: 0;
        left: 256px;
        right: 0;
        bottom: 0;
        background-color: @background-color-light;
        transition: all 0.2s;
    }
    .main.collapsed {
        .left {
            width: 80px;
        }
        .right {
            left: 80px;
        }
        .logo-title {
            width: 0;
        }
    }
    .logo-img-box {
        display: flex;
        align-items: center;
        padding-left: 24px;
        width: 100%;
        height: 64px;
        background-color: @menu-dark-bg;
        .logo-img {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            object-fit: contain;
        }
        .logo-title {
            margin-left: 12px;
            color: @background-color-light;
            font-size: 18px;
            overflow: hidden;
            white-space: nowrap;
        }
    }
</style>
